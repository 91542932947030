

















































import {Component, Vue, Watch} from 'vue-property-decorator';
import PostCard from '@/components/PostCard.vue';
import DropDown from '@/components/DropDown.vue';
import {vxm} from '@/store';
import {AffiliateContentResponse} from '@/types/AffiliateContentResponse';
import Loader from '@/components/Loader.vue';
import {PhotoInterface} from '@/types/photoInterface';
import {VideoInterface} from '@/types/videoInterface';

// New
import PostCardNew from '@/components/PostCardNew.vue';

@Component({
  components: {Loader, PostCard, DropDown, PostCardNew},
})
export default class PremiumContents extends Vue {
  featuredTitle = 'Premium Content';
  feedTitle = 'BUY';
  viewType = 'grid';
  premiumPosts: Array<AffiliateContentResponse | PhotoInterface | VideoInterface> = [];
  isMobile = false;
  isRequestSend = false;
  params = {
    videoType: 'vids+',
    page: 1,
    take: 11,
    isSaleVideo: true,
    isPurchased: false,
  };
  totalPosts = 0;
  randomPromoIndex = this.randomInt(3, 12);
  twoPremiumPosts: Array<AffiliateContentResponse | PhotoInterface | VideoInterface> = [];
  premiumFeatured: Array<AffiliateContentResponse | PhotoInterface | VideoInterface> = [];

  mounted() {
    this.getFirstPage();
    this.getFeaturedFeeds();
    // window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  destroyed() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    //const bottomOfWindow = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
    if (!this.isRequestSend && this.totalPosts > this.premiumPosts.length) {
      this.getData();
      this.$gtag.event('feed page', {
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_category: 'lazy loading',
      });
    }
  }

  // get random index to create blank space in grid
  randomInt(min: number, max: number) {
    return min + Math.floor((max - min) * Math.random());
  }

  onResize() {
    this.isMobile = window.innerWidth <= 1023;
  }

  getFirstPage() {
    this.params.page = 1;
    this.premiumPosts = [];
    this.getData();
  }

  // Load more data on button click
  getMoreData() {
    this.isRequestSend = true;
    this.getData();
  }

  get paymentSuccessCount() {
    return this.$store.state.paymentSuccessCount;
  }

  @Watch('paymentSuccessCount') onPaymentSuccess() {
    const take = (this.params.page as number) * (this.params.take as number);
    this.isRequestSend = true;
    vxm.fan
      .getPremiumContents({...this.params, page: 1, take: take})
      .then((res) => {
        this.totalPosts = res.data.count;
        this.premiumPosts = res.data.items;
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.isRequestSend = false;
      });
  }

  @Watch('paymentSuccessCount') onPaymentMessages() {
    const take = this.params.take as number;
    vxm.fan
      .getFeaturedContents({
        videoType: 'vids+',
        isPurchased: true,
        page: 1,
        take: take,
        status: 'active',
      })
      .then((res) => {
        this.premiumFeatured.unshift(res.data);
      })
      .catch((error) => {
        return error;
      });
  }

  getData(): void {
    const params = {...this.params};
    this.isRequestSend = true;
    vxm.fan
      .getPremiumContents(params)
      .then((res) => {
        this.totalPosts = res.data.count;
        this.premiumPosts.push(...res.data.items);
        (this.params.page as number)++;
        this.twoPremiumPosts = this.premiumPosts.splice(0, 2);
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.isRequestSend = false;
      });
  }

  getFeaturedFeeds() {
    vxm.fan
      .getFeaturedContents({
        videoType: 'vids+',
        isPurchased: true,
        page: 1,
        take: 1,
        status: 'active',
      })
      .then((res) => {
        this.premiumFeatured.push(res.data);
      })
      .catch((error) => {
        return error;
      });
  }

  switchView(viewType: string) {
    this.viewType = viewType;
  }
}
